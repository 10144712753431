import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/portScan.scss';
import { IPageProps } from '../../sharedInterfaces';
import { api, showSuccess, showInfo, showError, showLoading } from '../../sharedInterfaces';
import { hasAnyPrivileges, EPrivileges, IPortScan, IPortScanResult } from '../../apitypes/index';
import { IUser } from '../../apitypes/index';
import i18n from '../../i18n/I18n';
import { GiRadarSweep } from "react-icons/gi";
import moment from 'moment';

interface IState {
  user: IUser | null,
  portScans: IPortScan[],
  selected: IPortScan | null
  result: IPortScanResult | null,
  ip: string,
  ports: string,
}

class PortScan extends Component<IPageProps, IState> {
  timerUpdate: any;

  constructor(props: IPageProps) {
    super(props);

    this.timerUpdate = null;
    this.state = {
      user: null,
      portScans: [],
      selected: null,
      result: null,
      ip: "",
      ports: "21,22,80,443,5060"
    }
  }

  async componentDidMount(): Promise<void> {
    if (api.currentUser !== null) {
      showLoading(true);
      this.setState({
        user: await api.user.getUser(api.currentUser?.id)
      });
      await this.loadPortScans();
      showLoading(false);

      this.timerUpdate = setInterval(() => { this.loadPortScans() }, 10000);
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.timerUpdate);
  }




  async loadPortScans() {
    this.setState({
      portScans: await api.portScan.getPortScans() || []
    })
  }


  async startScan() {
    const { ip, ports } = this.state;
    if (ip.trim() === "" || ports.trim().split(',').length === 0) {
      showError(i18n.s('invalidIPorPorts'));
      return;
    }

    showLoading(true);
    await api.portScan.createPortScan(ip, ports);
    showLoading(false);
    this.setState({
      ip: '',
      ports: "21,22,80,443,5060"
    });
    showSuccess(i18n.s("portScanStarted"));
    await this.loadPortScans();
  }


  async selectPortScan(ps: IPortScan) {
    this.setState({
      selected: ps
    });
    showLoading(true);
    const results = await api.portScan.getPortScanResult(ps.id);
    this.setState({
      result: results
    });
    showLoading(false);

  }


  renderResult() {
    const { selected, result } = this.state;

    if (!selected || !result) {
      return null;
    }

    if (selected?.finished === false) {
      return i18n.s('portScanStillRunning');
    }

    if (!result) {
      return "Error: no result ! No error ! Strange...";
    }

    if (result.error) {
      return <>
        <h3>{i18n.s('portScanError')}</h3>
        <p className='danger'>
          {result.error}
        </p>
      </>
    }

    if (result.results) {
      return result.results.map((res) => {
        return <div className='portscan-result'>
          <div className='result-value'>
            <span>{i18n.s('hostname')}</span>
            <span>{res.hostname}</span>
          </div>
          <div className='result-value'>
            <span>{i18n.s('ip')}</span>
            <span>{res.ip}</span>
          </div>
          <div className='result-value'>
            <span>{i18n.s('mac')}</span>
            <span>{res.mac}</span>
          </div>
          <h4>{i18n.s('openPorts')}</h4>
          <table>
            <thead>
              <tr>
                <th>{i18n.s('protocol')}</th>
                <th>{i18n.s('port')}</th>
                <th>{i18n.s('service')}</th>
                <th>{i18n.s('status')}</th>
              </tr>
            </thead>
            <tbody>
              {
                res.openPorts?.map((op: any) => {
                  return <tr>
                    <td>{op.protocol}</td>
                    <td>{op.port}</td>
                    <td>{op.service}</td>
                    <td>{i18n.s('portOpen')}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      })
    }

    return "Hu ??? should not be there !";
  }

  render() {
    const { portScans, selected, ip, ports } = this.state;

    return (
      <div className='management portscan'>
        <h1>{i18n.s("portScan")}</h1>

        <div className='portscan-form'>
          <h3>{i18n.s("startNewPortScan")}</h3>
          <small>{i18n.s("portScanWarning")}</small>

          <div className='inline'>
            <div className='inline'>
              <label>{i18n.s('ip')}</label>
              <input type="text" value={ip} onChange={(e) => {
                this.setState({ ip: e.currentTarget.value.trim() })
              }} />
            </div>

            <div className='inline'>
              <label>{i18n.s('ports')}</label>
              <input type="text" value={ports} onChange={(e) => {
                this.setState({ ports: e.currentTarget.value.replace(' ', ',') })
              }} />
            </div>

            <div>
              <button onClick={() => { this.startScan() }} >{i18n.s("startPortScan")}</button>
            </div>
          </div>
        </div>

        <div className='portscan-contents'>
          <ul>
            {
              portScans.map((ps: IPortScan) => {
                return <li key={ps.id} className={selected && selected.id === ps.id ? 'selected' : ''}
                  onClick={() => {
                    if (ps.finished) {
                      this.selectPortScan(ps)
                    }
                    else {
                      this.selectPortScan(ps)
                      showInfo(i18n.s('portScanStillRunning'))
                    }
                  }}
                >
                  <span>{moment.unix(ps.date).format("DD/MM/YYYY HH:mm:ss")}</span>
                  <span>{ps.ip}</span>
                  {
                    ps.finished === false
                      ? <span className='rotate'><GiRadarSweep /></span>
                      : <span></span>
                  }

                </li>
              })
            }
          </ul>
          <div className='results'>
            <h3>{i18n.s('portScanResults')}</h3>
            {
              this.renderResult()
            }
          </div>
        </div>
      </div >
    );
  }
}

export default PortScan;