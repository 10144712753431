import React, { Component } from 'react';
import Dialog from '../../comps/dialog/Dialog';
import { IReseller, IUser, hasAnyPrivileges, EPrivileges, ENotifications, getPrivilegeDependencies } from '../../apitypes';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { VscMail, VscCheckAll } from 'react-icons/vsc';
import { ImUserTie } from 'react-icons/im';
import { MdOutlineRule } from 'react-icons/md';
import Select from 'react-select';


interface IProps {
  editUserID: number | null,
  onCancel: Function,
  onSave: Function
}

interface IState {
  user: IUser | null,
  resellers: IReseller[],
  error: string | null,
  loading: boolean,
}

class EditUserForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      user: null,
      resellers: [],
      error: null,
      loading: true,
    }
  }


  async componentDidMount(): Promise<void> {
    const { editUserID } = this.props;
    const resellers = await api.reseller.getResellers()

    const user = editUserID !== null ? await api.user.getUser(editUserID) : {
      id: -1,
      email: '',
      reseller: -1,
      cgvSeen: null,
      privileges: [],
      notifications: []
    };
    this.setState({
      resellers,
      user,
      loading: false
    })
  }


  async saveObject() {
    const { onSave } = this.props;
    const { user } = this.state;

    if (user === null) {
      return;
    }

    if (user.id === -1) {
      if (user.email === "") {
        this.setState({
          error: i18n.s("noUserEmail")
        });
        return;
      }
      const createdUser = await api.user.createUser(user);
      if (createdUser) {
        onSave(createdUser);
      }
      else {
        this.setState({
          error: i18n.s("userCreateError")
        });
      }
    }
    else {
      const updatedUser = await api.user.updateUser(user.id, user);
      if (updatedUser) {
        onSave(updatedUser);
      }
      else {
        this.setState({
          error: i18n.s("userUpdateError")
        });
      }
    }
  }


  clearPrivileges(): Promise<void> {
    return new Promise((resolve) => {
      const { user } = this.state;
      if (!user) {
        return resolve();
      }
      this.setState({
        user: {
          ...user,
          privileges: []
        }
      }, () => {
        return resolve();
      })
    })
  }

  addPrivilege(ep: EPrivileges): Promise<void> {
    return new Promise(async (resolve) => {
      const deps = getPrivilegeDependencies(ep);
      for (const d of deps) {
        await this.addPrivilege(d);
      }

      //On récupère l'objet a editer APRES avoir éventuellement ajouté les références. sinon on modifie l'objet initial et pas celui ou on a ajouté les dépendences
      const { user } = this.state;
      if (!user) {
        return resolve();
      }


      if (user.privileges.find(pri => pri === ep)) {
        return resolve(); //already there !
      }

      this.setState({
        user: {
          ...user,
          privileges: [
            ...user.privileges,
            ep
          ]
        }
      }, () => {
        return resolve();
      })
    })
  }

  removePrivilege(ep: EPrivileges): Promise<void> {
    return new Promise(async (resolve) => {
      if (this.state.user === null) {
        return;
      }
      //If this privilege is a dependency of another privilege, we must remove the parent privilege.
      //we check all current user privileges, and if this privilege is one of dep, we remove it
      const usersPrivileges = this.state.user?.privileges;
      for (const p of usersPrivileges) {
        if (p !== ep) {
          const deps = getPrivilegeDependencies(p);
          //if the privilege we remove is in this array, remove also p !
          if (deps.includes(ep)) {
            await this.removePrivilege(p);
          }
        }
      }

      const { user } = this.state;
      if (!user) {
        return resolve();
      }

      this.setState({
        user: {
          ...user,
          privileges: user.privileges.filter(pri => pri !== ep)
        }
      }, () => {
        return resolve();
      })
    })
  }


  render() {
    const { user, resellers, loading } = this.state;
    const { onCancel } = this.props;

    if (user === null) {
      return null;
    }

    const notifications = Object.values(ENotifications);

    return <Dialog
      title={i18n.s("createUser")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        onCancel();
      }}
      onOK={async () => {
        this.saveObject();
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><VscMail /> <label>{i18n.s('email')}</label></div>
            <input autoComplete='off' type="text" value={user.email} onChange={(e) => {
              this.setState(
                {
                  user: {
                    ...user,
                    email: e.currentTarget.value
                  }
                }
              )
            }} />
          </div>
          <div className='row input-with-label'>
            <div><ImUserTie /> <label>{i18n.s('resellerName')}</label></div>
            <select value={user !== null && user.reseller !== null ? user.reseller : ""} onChange={(e) => {
              this.setState(
                {
                  user: {
                    ...user,
                    reseller: e.currentTarget.value !== "" ? Number.parseInt(e.currentTarget.value) : -1
                  }
                }
              )
            }} >
              {
                hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
                  ? <option value=''></option>
                  : null
              }

              {
                resellers.map((r) => {
                  return <option key={r.id} value={r.id}>{r.company}</option>
                })
              }
            </select>
          </div>
          {
            hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
              ? <div className='row input-with-label'>
                <div><MdOutlineRule /> <label>{i18n.s('userPrivileges')}</label></div>
                <ul>
                  {
                    Object.values(EPrivileges).map((ep) => {
                      return <li>
                        <input type="checkbox"
                          value={ep} checked={user.privileges.findIndex(pri => pri === ep) > -1}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              this.addPrivilege(ep);
                            }
                            else {
                              this.removePrivilege(ep);
                            }
                          }}
                        />
                        <label>{i18n.s(ep)}</label>
                      </li>
                    })
                  }
                </ul>
                <div className='buttons'>
                  <button onClick={async () => {
                    await this.clearPrivileges();
                    await this.addPrivilege(EPrivileges.READ_FILES);
                  }}><VscCheckAll /><label>{i18n.s('basicCustomer')}</label></button>
                  <button onClick={async () => {
                    await this.clearPrivileges();
                    await this.addPrivilege(EPrivileges.READ_FILES);
                    await this.addPrivilege(EPrivileges.READ_USERS);
                    await this.addPrivilege(EPrivileges.WRITE_USERS);
                  }}><VscCheckAll /><label>{i18n.s('advancedCustomer')}</label></button>
                </div>

              </div>
              : null
          }
          {
            hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
              ? <div className='row input-with-label'>
                <div><ImUserTie /> <label>{i18n.s('userNotifications')}</label></div>
                <Select
                  className='react-select'
                  classNamePrefix='react-select'
                  isMulti
                  value={
                    user.notifications.map((n) => {
                      return { value: ENotifications[n], label: i18n.s(n) }
                    })}
                  onChange={(e) => {
                    this.setState({
                      user: {
                        ...user,
                        notifications: e.map(option => option.value)
                      }
                    })
                  }}
                  options={
                    notifications.map((n) => {
                      return { value: ENotifications[n], label: i18n.s(n) }
                    })
                  }
                  menuPlacement='top'
                />
                <div className='buttons'>
                  <button onClick={() => {
                    this.setState(
                      {
                        user: {
                          ...user,
                          notifications: Object.values(ENotifications).map((ep) => { return ep })
                        }
                      }
                    )
                  }}><VscCheckAll /><label>{i18n.s('allNotifications')}</label></button>
                </div>
              </div>
              : null
          }

        </div>

      </div>

      {loading ? <div className='loading' ></div> : null}
    </Dialog >
  }
}

export default EditUserForm;