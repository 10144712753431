import { IPortScan, IPortScanResult } from '../../apitypes';
import { GetAPI, PostAPI, PutAPI, DeleteAPI } from './http';

export default class PortScanModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }

  async getPortScans(): Promise<IPortScan[] | null> {
    let response = await GetAPI(`${this.baseUri}/portscan`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async createPortScan(ip: string, ports: string): Promise<number | null> {
    let response = await PostAPI(`${this.baseUri}/portscan`, this.token, { ip, ports }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

  async portScanFinished(id: number): Promise<boolean> {
    let response = await GetAPI(`${this.baseUri}/portscan/finished/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

  async getPortScanResult(id: number): Promise<IPortScanResult | null> {
    let response = await GetAPI(`${this.baseUri}/portscan/${id}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }

}




