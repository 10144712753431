import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/centrex.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { IBareMetal, IReseller, hasAnyPrivileges, EPrivileges } from '../../apitypes/index';
import EditBaremetalForm from './EditBaremetalForm';
import CloneProxmoxTemplate from "./ClonePromoxTemplate";
import EditProxmoxVM from "./EditProxmoxVM";


import { VscSearch, VscRefresh, VscTerminal, VscBrowser, VscCopy } from 'react-icons/vsc'
import { BsPlus } from 'react-icons/bs';
import { RxPencil1 } from 'react-icons/rx';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdStop, MdPlayArrow } from 'react-icons/md';

interface IState {
  search: string,
  resellers: IReseller[],
  bareMetals: IBareMetal[] | null,
  editBareMetal: IBareMetal | null,
  vms: any[],
  sortBaremetalBy: string,
  sortBaremetalOrder: E_SORTORDER,
  sortVMBy: string,
  sortVMOrder: E_SORTORDER,
  cloneTemplate: any | null,
  editVM: any | null,
  filterVMOnBaremetal: number | null,
  filterFullBaremetals: boolean
}

class CentrexManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      resellers: [],
      bareMetals: null,
      vms: [],
      editBareMetal: null,
      sortBaremetalBy: 'domain',
      sortBaremetalOrder: E_SORTORDER.ASC,
      sortVMBy: 'vmid',
      sortVMOrder: E_SORTORDER.ASC,
      cloneTemplate: null,
      editVM: null,
      filterVMOnBaremetal: null,
      filterFullBaremetals: false
    }
  }


  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  getDuration(sec: number): string {
    let min = 0;
    let hours = 0;
    let days = 0;
    while (sec >= 60) {
      min++;
      sec -= 60;
      if (min === 60) {
        hours++;
        min = 0;
      }
      if (hours === 24) {
        days++;
        hours = 0;
      }
    }
    return `${days}j, ${hours < 10 ? "0" + hours : hours}h${min < 10 ? "0" + min : min}m${sec < 10 ? "0" + sec : sec}s`;
  }

  sortBaremetalsObjectsBy(sortBy: string) {
    try {
      const { bareMetals, sortBaremetalOrder } = this.state;
      if (bareMetals === null) {
        return;
      }

      let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
      if (sortBy === this.state.sortBaremetalBy) {
        //Change sort order
        if (sortBaremetalOrder === E_SORTORDER.ASC) {
          newSortOrder = E_SORTORDER.DESC;
        }
      }

      this.setState({
        sortBaremetalOrder: newSortOrder,
        sortBaremetalBy: sortBy,
        bareMetals: [...sortObjects(bareMetals, sortBy, newSortOrder)]
      });
    }
    catch (e: any) {
      console.error(`Sort baremetal error: ${e.message}`)
    }
  }

  sortVMsObjectsBy(sortBy: string) {
    try {
      const { vms, sortVMOrder } = this.state;
      let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
      if (sortBy === this.state.sortVMBy) {
        //Change sort order
        if (sortVMOrder === E_SORTORDER.ASC) {
          newSortOrder = E_SORTORDER.DESC;
        }
      }

      this.setState({
        sortVMOrder: newSortOrder,
        sortVMBy: sortBy,
        vms: [...sortObjects(vms, sortBy, newSortOrder)]
      });
    }
    catch (e: any) {
      console.error(`Sort vms error: ${e.message}`)
    }
  }

  async reloadObjects() {
    const { sortBaremetalBy, sortBaremetalOrder, sortVMBy, sortVMOrder } = this.state;

    showLoading(true);
    this.setState({
      bareMetals: null
    });

    const resellers = await api.reseller.getResellers();
    const bareMetals = hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN]) ? await api.baremetal.getBareMetals() : null;

    const vms = await api.baremetal.getAllVMs();
    this.setState({
      resellers,
      bareMetals: bareMetals ? sortObjects(bareMetals, sortBaremetalBy, sortBaremetalOrder) : null,
      editBareMetal: null,
      vms: sortObjects(vms, sortVMBy, sortVMOrder)
    });

    showLoading(false);
  }

  async deleteBareMetal(bm: IBareMetal) {


    if (bm.id === null) {
      return null;
    }

    if (window.confirm(i18n.s("confirmDeleteBareMetal", [bm.domain]))) {
      if (await api.baremetal.deleteBareMetal(bm.id)) {
        showSuccess(i18n.s('bareMetalDeleted'));
        this.reloadObjects();
      }
      else {
        showError(i18n.s("failedToDeleteBareMetal"))
      }
    }
  }


  async deleteVM(vm: any) {
    const id = window.prompt(i18n.s('proxmoxDeleteConfirm', [vm.vmid, vm.name, vm.vmid]));
    if (id && parseInt(id) === vm.vmid) {
      showInfo(i18n.s('taskQueued'));
      const result = await api.baremetal.deleteBaremetalVM(vm.baremetal, vm.vmid);
      console.log(result);
      this.reloadObjects();
    }
  }

  async stopVM(vm: any) {
    if (window.confirm(i18n.s('stopVMConfirmation', [vm.name]))) {
      showInfo(i18n.s('taskQueued'));
      const result = await api.baremetal.stopBaremetalVM(vm.baremetal, vm.vmid);
      console.log(result);
      this.reloadObjects();
    }
  }

  async startVM(vm: any) {
    if (window.confirm(i18n.s('startVMConfirmation', [vm.name]))) {
      showInfo(i18n.s('taskQueued'));
      const result = await api.baremetal.startBaremetalVM(vm.baremetal, vm.vmid);
      console.log(result);
      this.reloadObjects();
    }
  }


  renderBaremetals() {
    const { bareMetals, vms, search, filterFullBaremetals } = this.state;

    if (bareMetals === null) {
      return null;
    }

    return <div>
      <h2>{i18n.s("baremetalServers")}</h2>

      <div>
        <span>
          <input type="checkbox" checked={filterFullBaremetals} onChange={(e) => {
            this.setState({
              filterFullBaremetals: e.currentTarget.checked
            })
          }} />
          <label>{i18n.s('filterFullBaremetals')}</label>
        </span>
      </div>
      <div className='button-actions'>
        <button className='success' title={i18n.s('addBareMetal')} onClick={() => {
          this.setState({
            editBareMetal: {
              id: null,
              description: '',
              domain: '',
              node: '',
              maxvm: 16,
              ssh: 22,
              http: 0,
              https: 8006
            }
          })
        }}><BsPlus /></button>
      </div>


      <table>
        <thead>
          <tr>
            <th></th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("domain") }}>{i18n.s('domain')}</th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("node") }}>{i18n.s('nodeName')}</th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("description") }}>{i18n.s('description')}</th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("ssh") }}>{i18n.s('sshPort')}</th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("http") }}>{i18n.s('httpPort')}</th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("https") }}>{i18n.s('httpsPort')}</th>
            <th className="sortable" onClick={() => { this.sortBaremetalsObjectsBy("maxvm") }}>{i18n.s('bareMetalVMCount')}</th>
            <th>{i18n.s('bareMetalFull')}</th>
          </tr>
        </thead>
        <tbody>
          {
            bareMetals.filter((bm) => {
              if (filterFullBaremetals === false) {
                return true;
              }
              const bmVMS = vms.filter((v) => { return v.baremetal === bm.id && v.template === 0 });
              if (bmVMS.length >= bm.maxvm) {
                return false;
              }
              return true;
            }).filter((bm) => {
              if (search.trim() !== "") {
                const srch = search.toLowerCase();
                return bm.domain.includes(srch) || bm.node.includes(srch) || bm.description.includes(srch);
              }
              else {
                return true;
              }
            }).map((bm) => {
              //count vm on this BM
              const bmVMS = vms.filter((v: any) => { return v.baremetal === bm.id && v.template === 0 });

              return <tr key={bm.domain} className='baremetal-tr'>
                <td>
                  <div className='buttons'>
                    <button className='info' title={i18n.s("editVM")} onClick={() => {
                      this.setState({
                        editBareMetal: bm
                      });
                    }}><RxPencil1 /></button>
                    <button className='danger' title={i18n.s("editVM")} onClick={() => {
                      this.deleteBareMetal(bm);
                    }}><AiOutlineDelete /></button>
                  </div>
                </td>
                <td>{bm.domain}</td>
                <td>{bm.node}</td>
                <td>{bm.description}</td>
                <td>
                  {
                    bm.ssh ? <a href={`ssh://root@${bm.domain}:${bm.ssh}`} target='_blank' rel="noreferrer" ><VscTerminal /> {bm.ssh}</a> : null
                  }
                </td>
                <td>
                  {
                    bm.http ? <a href={`http://${bm.domain}:${bm.http}`} target='_blank' rel="noreferrer" ><VscBrowser /> {bm.http}</a> : null
                  }
                </td>
                <td>
                  {
                    bm.https ? <a href={`http://${bm.domain}:${bm.https}`} target='_blank' rel="noreferrer" ><VscBrowser /> {bm.https}</a> : null
                  }
                </td>
                <td>{bmVMS.length}/{bm.maxvm}</td>
                <td>{bmVMS.length >= bm.maxvm ? i18n.s("yes") : i18n.s("no")}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  }

  renderVMs() {
    const { bareMetals, filterVMOnBaremetal, vms, search, resellers } = this.state;

    //list baremetals in select. we get it from vmsi if baremetals is not defined or null
    let bmsForSelect: any[] = [];
    if (bareMetals) {
      bmsForSelect = bareMetals.map((b) => {
        return {
          id: b.id,
          name: b.domain
        }
      });
    }
    else {
      for (const v of vms) {
        if (bmsForSelect.indexOf((b: any) => { return b.id === v.baremetal }) === -1) {
          bmsForSelect.push({
            id: v.baremetal,
            name: `SRV_${v.baremetal}`
          })
        }
      }
    }

    const filteredVms = filterVMOnBaremetal ? vms.filter(v => v.baremetal === filterVMOnBaremetal) : vms;

    return <div>
      <h2>{i18n.s("baremetalVMS")}</h2>
      <div className='inline'>
        <label>{i18n.s('baremetalFilter')}</label>
        <select value={filterVMOnBaremetal ? filterVMOnBaremetal : ''} onChange={(e) => { this.setState({ filterVMOnBaremetal: e.currentTarget.value !== '' ? parseInt(e.currentTarget.value) : null }) }}>
          <option value=''>{i18n.s('anyBaremetal')}</option>
          {
            bmsForSelect.map((b) => {
              return <option value={b.id}>{b.name}</option>
            })
          }
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: "60px" }}></th>
            <th className="sortable" onClick={() => { this.sortVMsObjectsBy("baremetal") }}>{i18n.s("baremetal")}</th>
            <th className="sortable" onClick={() => { this.sortVMsObjectsBy("reseller") }}>{i18n.s("reseller")}</th>
            <th style={{ width: "60px" }} className="sortable" onClick={() => { this.sortVMsObjectsBy("vmid") }}>{i18n.s("vmid")}</th>
            <th style={{ width: "60px" }} className="sortable" onClick={() => { this.sortVMsObjectsBy("template") }}>{i18n.s("isTemplate")}</th>
            <th className="sortable" onClick={() => { this.sortVMsObjectsBy("name") }}>{i18n.s("name")}</th>
            <th></th>
            <th>{i18n.s('macAddress')}</th>
            <th style={{ width: "150px" }} className="sortable center" onClick={() => { this.sortVMsObjectsBy("uptime") }}>{i18n.s("uptime")}</th>
            <th style={{ width: "80px" }} className="sortable center" onClick={() => { this.sortVMsObjectsBy("cpus") }}>{i18n.s("cpu")}</th>
            <th style={{ width: "80px" }} className="sortable center" onClick={() => { this.sortVMsObjectsBy("maxmem") }}>{i18n.s("memory")}</th>
            <th style={{ width: "80px" }} className="sortable center" onClick={() => { this.sortVMsObjectsBy("maxdisk") }}>{i18n.s("disk")}</th>
          </tr>
        </thead>
        <tbody>
          {
            filteredVms.filter((v) => {
              //search if search if defined
              if (search.trim() === "") {
                return true;
              }
              return v.name.toLowerCase().includes(search.toLowerCase());
            }).map((v) => {
              const baremetal = bareMetals !== null ? bareMetals.find(b => b.id === v.baremetal) || null : null;

              const reseller = v.reseller ? resellers.find(r => r.id === parseInt(v.reseller)) || null : null;
              const macTab = v.config && v.config.net0 ? v.config.net0.match(/([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})/gm) : null;

              const maxMem = Math.round((v.maxmem / 1024 / 1024 / 1024) * 10) / 10;
              const maxDisk = Math.round((v.maxdisk / 1024 / 1024 / 1024) * 10) / 10;
              const mem = v.mem > 0 ? Math.round((v.mem / 1024 / 1024 / 1024) * 10) / 10 : 0;
              const memPercentUsed = maxMem > 0 ? Math.ceil((mem * 100) / maxMem) : 0;

              return <tr key={`${v.baremetal}_${v.vmid}`}>
                <td>
                  <div className="buttons">
                    {
                      hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_VMS])
                        ? <button className="danger" onClick={() => {
                          this.deleteVM(v);
                        }}><AiOutlineDelete /></button>
                        : null
                    }
                    {
                      v.template === undefined || v.template === 0
                        ? v.status === "running"
                          ? <button className="warning" onClick={() => { this.stopVM(v) }}><MdStop /></button>
                          : <button className="success" onClick={() => { this.startVM(v) }}><MdPlayArrow /></button>
                        : null
                    }
                    {
                      v.template === undefined || v.template === 0
                        ? <button className='info' title={i18n.s("editVM")} onClick={() => {
                          this.setState({
                            editVM: v
                          })
                        }}><RxPencil1 /></button>
                        : null
                    }
                    {
                      baremetal && v.template && v.template === 1
                        ? <button className='info' title={i18n.s("cloneTemplate")} onClick={() => {
                          this.setState({
                            cloneTemplate: v
                          })
                        }}><VscCopy /></button>
                        : null
                    }
                  </div>
                </td>
                <td>{baremetal ? baremetal.domain : `SRV_${v.baremetal}`}</td>
                <td>{reseller ? reseller.company : ''}</td>
                <td className="center">{v.vmid}</td>
                <td className="center">{v.template && v.template === 1 ? i18n.s("yes") : i18n.s("no")}</td>
                <td>{v.name}</td>
                <td>
                  <div className='shortcuts'>
                    <a href={`ssh://root@${v.name}`} target='_blank' rel="noreferrer" ><VscTerminal /> ssh</a>
                    <a href={`http://${v.name}`} target='_blank' rel="noreferrer" ><VscBrowser /> www</a>
                  </div>
                </td>
                <td>{macTab && macTab.length > 0 ? macTab[0] : ''}</td>
                <td>{this.getDuration(v.uptime)}</td>
                <td className="center">{v.cpus}</td>
                <td className="center">{memPercentUsed}% ({mem} / {maxMem} GB)</td>
                <td className="center">{maxDisk} GB</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  }

  render() {
    const { search, resellers, editBareMetal, cloneTemplate, editVM } = this.state;
    return (
      <Fragment>
        <div className='management centrex'>
          <h1>{i18n.s("Centrex")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value, filterVMOnBaremetal: null }) }} />
            </div>
            <div className='search-options'>
            </div>
          </div>

          <div className='button-actions'>
            <button title={i18n.s('reload')} onClick={() => {
              this.setState({
                bareMetals: []
              }, () => {
                this.reloadObjects()
              })
            }}><VscRefresh /></button>
          </div>

          <div className='baremetals'>
            {
              this.renderBaremetals()
            }

            {
              this.renderVMs()
            }
          </div>

          {
            editBareMetal
              ? <EditBaremetalForm
                editBaremetal={editBareMetal}
                onCancel={() => { this.setState({ editBareMetal: null }) }}
                onSave={() => { this.reloadObjects(); }}
              />
              : null
          }

          {
            cloneTemplate
              ? <CloneProxmoxTemplate
                onCancel={() => {
                  this.setState({
                    cloneTemplate: null
                  })
                }}
                onSave={async (bareMetalID: number, templateVMID: number, newVMID: number, diskSize: number, config: any) => {
                  this.setState({
                    cloneTemplate: null
                  })
                  showInfo(i18n.s('taskQueued'));

                  const operationLog = await api.baremetal.cloneBaremetalTemplate(bareMetalID, templateVMID, newVMID, diskSize > 0 ? `+${diskSize}G` : null, config);
                  if (operationLog) {
                    console.log(operationLog);
                    showSuccess(i18n.s('proxmoxCloneOK'));
                  }
                  else {
                    console.error(operationLog);
                    showError(i18n.s('proxmoxCloneError'));
                  }

                  this.reloadObjects();
                }}
                resellers={resellers}
                template={cloneTemplate}
              />
              : null
          }

          {
            editVM
              ? <EditProxmoxVM
                onCancel={() => {
                  this.setState({
                    editVM: null
                  })
                }}
                onSave={async (baremetalID: number, vmid: number, config: any) => {
                  this.setState({
                    editVM: null
                  })
                  showInfo(i18n.s('taskQueued'));
                  const operationLog = await api.baremetal.updateBaremetalVMConfig(baremetalID, vmid, config);
                  if (operationLog) {
                    console.log(operationLog);
                    showSuccess(i18n.s("proxmoxVMEditSuccess"));
                  }
                  else {
                    console.error(operationLog);
                    showError(i18n.s('proxmoxVMEditError'));
                  }
                  showLoading(false);
                  this.reloadObjects();
                }}
                resellers={resellers}
                vm={editVM}
              />
              : null
          }


        </div>
      </Fragment>
    );
  }
}

export default CentrexManagement;